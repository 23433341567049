<template>
  <base-section
    id="shows"
    class="accent"
  >
    <div class="bg-pixel" />
    <base-section-heading
      class="primary--text"
      title="Shows"
    />
    <v-container class="pr-16 pl-16">
      <h3

        class="text-h5 font-weight-bold mr-6 ml-6 mb-4 text-center"
      >
        Tanzt am Donnerstag oder Mittwoch einfach mit. Die erste Trainingslektion ist kostenlos!
      </h3>
    </v-container>
    <div
      class="py-14"
    />
    <v-container>
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          md="4"
          class="text-center"
        >
          <v-avatar
            size="240"
            class="elevation-10"
          >
            <img
              :src="require('@/assets/swissshow.jpg')"
              alt="DPC Swiss Show"
            >
          </v-avatar>
        </v-col>
        <v-col
          cols="12"
          md="7"
        >
          <div class="ml-6 mr-8">
            <h3 class="text-uppercase text-h5 primary--text">
              DPC Crew-Show
            </h3>
          </div>

          <v-expansion-panels
            flat
            class="hello"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="list-header">
                + Mehr Erfahren
              </v-expansion-panel-header>
              <v-expansion-panel-content class="list-content mr-10">
                Wir wollen eine neue Generation aufbauen, und geben unseren jungen Talenten die Möglichkeit, auf grossen
                Bühnen zu stehen, und ihre Träume zu verwirklichen. Wir freuen uns auf neue Schüler in unseren
                Tanzschulen in Dübendorf, Zürich, Baden und Bachenbülach.
                <br><br>
                Uns kann man auch für Auftritte, Workshops und weitere Projekte buchen. Nimm hierfür mit uns Kontakt
                auf.<div
                  class="py-3"
                />
                <div class="text-h6 mr-12 primary--text text-none text-left">
                  <a href="https://www.youtube.com/watch?v=Ee5PaAIeSMc">Youtube</a>
                  <div
                    class="py-2"
                  />
                  <div class="text-h6 mr-12 primary--text text-none text-left">
                    <a href="https://www.youtube.com/watch?v=MqDyJasrNUU">Youtube</a>
                    <div
                      class="py-2"
                    />
                    <div class="text-h6 mr-12 primary--text text-none text-left">
                      <a href="https://www.youtube.com/watch?v=-vRqZonoG4U">Youtube</a>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-content>
              <v-divider
                horizontal
                width="590"
                class="divider mx-6"
              />
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col
          cols="12"
          md="1"
          class="text-center"
        />
      </v-row>
      <div
        class="py-14"
      />
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          md="4"
          class="text-center"
        >
          <v-avatar
            size="240"
            class="elevation-10"
          >
            <img
              :src="require('@/assets/BS_Shows_SwissShow.jpg')"
              alt="DPC Swiss Show"
            >
          </v-avatar>
        </v-col>

        <v-col
          cols="12"
          md="7"
        >
          <div class="pl-6 mr-8">
            <h3 class="text-uppercase text-h5 primary--text">
              Swiss Show
            </h3>
          </div>

          <v-expansion-panels
            flat
            class="hello"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="list-header">
                + Mehr Erfahren
              </v-expansion-panel-header>
              <v-expansion-panel-content class="list-content mr-10">
                Die Breakdancer der BBCS präsentieren mit ihren Showacts tänzerische Unterhaltungskunst auf höchstem
                Niveau. Basis der anspruchsvollen Events ist Breakdance, in dieser Disziplin gehören die Tänzer unserer
                Company zur weltweiten Creme-de la-Creme. Die BBCS zeigt eine gelungene Verbindung scheinbarer
                Gegensätze in spektakulären Events – mit viel Akrobatik und Action die jeden Auftritt zu einem
                einzigartigen Showact machen.
                <div
                  class="py-3"
                />
                <div class="text-h6 mr-12 primary--text text-none text-left">
                  <a href="https://www.youtube.com/watch?v=9Xt555cyHBY">Youtube</a>
                  <br>
                </div>
              </v-expansion-panel-content>
              <v-divider
                horizontal
                width="590"
                class="divider mx-6"
              />
            </v-expansion-panel>
            <v-expansion-panel />
          </v-expansion-panels>
        </v-col>
        <v-col
          cols="12"
          md="1"
          class="text-center"
        />
      </v-row>
    </v-container>
    <div
      class="py-14"
    />
    <div class="bg-pixel-down" />
  </base-section>
</template>

<script>
  export default {
    name: 'SectionProChart',

    data: () => ({
      features: [
        ['Components', 5, '40+'],
        ['Example Pages', 3, '10+'],
        ['Vue CLI Support', true, true],
        ['Bugfixes and Issues', false, true],
        ['6 Months Free Updates', false, true],
        ['Supports Vuetify', false, true],
        ['Price', 'Free', '$59'],
      ],
    }),
  }
</script>

<style scoped>
.v-application .accent {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: transparent !important;
}

.list-header {
  font-size: 1.2rem !important;
  font-weight: 700;
}

.list-content {
  font-size: 1rem !important;
  font-weight: 600;
  line-height: 1.5rem;
}

.divider {
  border-color: rgba(12,12,12, 0.59) !important;
  border-width: 2px 0 0 0;
  margin-botttom: 4px;
}

.v-application .text-h5 {
  font-weight: 900;
  font-size: 1.5rem;
}

.bg-pixel {
  background-image: url('~@/assets/gradient-black.png');
  background-repeat: repeat-x;
  height: 80px;
  background-size: contain;
  width: 100%;
  position: relative;
  z-index: 3;
}

.bg-pixel-down {
  height: 80px;
  margin-top: -80px;
  background-image: url('~@/assets/gradient_red.png');
  background-repeat: repeat-x;
  background-size: contain;
  transform: scaleY(-1);
  width: 100%;
  position: absolute;
  z-index: 3;
}
</style>
